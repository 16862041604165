


































import Vue from 'vue';
export default Vue.extend({
  props: {
    text: Number,
  },
  data() {
    return {
      value: null,
      editable: false,
    };
  },
  watch: {
    text: {
      handler(newV) {
        this.$set(this, 'value', newV);
      },
      immediate: true,
    },
  },
  methods: {
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    check() {
      this.editable = false;
      this.$emit('change', this.value);
    },
    edit() {
      this.editable = true;
    },
  },
});
